import {timeFormat, numberFormat} from "@custom";

export const tableColumn = [
    {
        label: "序号",
        type: "index",
        width: 60,
    },
    {
        prop: "receLeasor.name",
        label: "接收人",
        width: 80
    },
    {
        prop: "typeName",
        label: "类型",
        width: 100,
    },
    {
        prop: "title",
        label: "消息标题",
        showOverflowTooltip: true
    },
    {
        prop: "content",
        label: "消息内容",
        showOverflowTooltip: true
    },
    {
        prop: "sendTime",
        label: "发送时间",
        width: 150,
        render: (h, {row}) => {
            const {sendTime} = row;
            const time = !sendTime ? "" : timeFormat(new Date(sendTime), 'yyyy-MM-dd HH:mm:ss');
            return h("span", time);
        }
    },
    {
        prop: "sendType",
        label: "发送类型",
        width: 80,
    },
    {
        prop: "receTime",
        label: "接收时间",
        width: 150,
        render: (h, {row}) => {
            const {receTime} = row;
            const time = !receTime ? "" : timeFormat(new Date(receTime), 'yyyy-MM-dd HH:mm:ss');
            return h("span", time);
        }
    },
    {
        prop: "recceStatus",
        label: "接收状态",
        width: 80,
    },
    {
        prop: "readTime",
        label: "已读时间",
        width: 150,
        render: (h, {row}) => {
            const {readTime} = row;
            const time = !readTime ? "" : timeFormat(new Date(readTime), 'yyyy-MM-dd HH:mm:ss');
            return h("span", time);
        }
    },
    {
        prop: "isRead",
        label: "是否已读",
        width: 80,
        render: (h, {row}) => {
            const {isRead} = row;
            return h("span", isRead?'已读':'未读');
        }
    },
];