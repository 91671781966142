<template>
    <section class="renewal-to-examine">
        <div class="top-bar bg-white" style="justify-content: left">
            <el-date-picker v-model="value1" type="daterange" value-format="yyyy-MM-dd" format="yyyy-MM-dd" size="small"
                            range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" style="margin-right: 10px;"/>
            <el-input v-model="formSearch.keyWord" size="small" style="width: 200px;margin-right: 10px;" placeholder="请输入申请人"/>
            <el-input v-model="formSearch.keyWord" size="small" style="width: 200px;margin-right: 10px;" placeholder="请输入审批人"/>
            <el-input v-model="formSearch.keyWord" size="small" style="width: 200px;margin-right: 10px;" placeholder="请输入房间号"/>
            <el-select v-model="formSearch.natureCode" placeholder="请选择状态" style="width: 200px;margin-right: 10px" size="small">
                <el-option v-for="item in natureCodeList" :label="item.codeName" :value="item.code" :key="item.code"/>
            </el-select>
            <el-button type="primary" @click="handleSearch" size="small">查询</el-button>
            <el-button @click="handleReset" size="small">重置</el-button>
        </div>
        <r-e-table class="bg-white" ref="tableRef" :dataRequest="getMessagePagerTableData" :columns="tableColumn"
                   :query="formSearch" :height="760">
            <template slot="empty">
                <el-empty/>
            </template>
        </r-e-table>
    </section>
</template>

<script>
    import { tableColumn } from "@/views/system-management/message-list/data";
    import { messagePager } from "@/api/message";
    export default {
        name: "message-list",
        data() {
            return {
                tableColumn: tableColumn,
                formSearch: {
                    keyWord: null,
                    startDate: null,
                    endDate: null,
                },
                natureCodeList: [],
                value1: [],
            };
        },
        components: {},
        methods: {
            getMessagePagerTableData(params) {
                return messagePager(params);
            },
            handleSearch() {
                this.$refs["tableRef"].getTableData();
            },
            handleReset() {
                this.formSearch = {};
                Promise.resolve(this.formSearch).then(() => this.handleSearch());
            },
        },
        async mounted() {
        },
    }
</script>

<style lang="scss" scoped>
    .renewal-to-examine {
        padding: VH(15px) VW(15px);

        .top-bar {
            display: flex;
            margin: VH(10px) 0 VH(25px);
            padding: VH(15px) VW(15px);
            justify-content: center;

            .el-radio-button.is-active {
                box-shadow: 0 0 10px #DDD inset;
            }
        }

        /deep/ .r-e-table {
            .el-table .cell {
                white-space: nowrap;
                text-align: center;
            }
        }
    }
</style>